export class MAuth {
    static SET_CODE = 'setCode';
    static SET_VERIFIER = 'setVerifier';
    static SET_VERIFIER_HASH = 'setVerifierHash';
    static SET_ACCESS_TOKEN = 'setAccessToken';
    static SET_USER = 'setUser';
}

export class MUser {
    static SET_BANK_ACCOUNTS = 'setBankAccounts';
    static SET_ADDRESSES = 'setAddresses';
    static SET_INVOICE = 'setInvoice';
    static SET_INVOICES_LOADING = 'setInvoicesLoading';
    static SET_BANK_DETAILS = 'setBankDetails';
    static SET_USER_DATA = 'setUserData';
    static SET_USER_COMMISSION = 'setUserCommission';
    static SET_USER_LOGGED = 'setUserLogged';
    static SET_INVOICES = 'setInvoices';
    static SET_DECLARATIVE_DATA = 'setDeclarativeData';
    static SET_KYC_STATUS = 'setKycStatus';
    static SET_WALLET_BALANCE = 'setWalletBalance';
    static SET_WALLET_TRANSACTIONS = 'setWalletTransactions';
    static RESET_WALLET_TRANSACTIONS = 'resetWalletTransactions';
    static SET_INVOICE_DETAILS = 'setInvoiceDetails';
    static SET_NOTIFICATIONS = 'setNotifications';
    static SET_MAINTENANCE = 'setMaintenance';
    static SET_NOTIFICATION_SETTINGS = 'setNotificationSettings';
    static SET_VISITOR_COUNTRY = 'setVisitorCountry';
    static SET_SELECTED_MULTIPLE_INVOICES_FROM_INVOICE_CART = 'setSelectedMultipleInvoicesFromInvoiceCart'
}

export class MOrders {
    static SET_BIDS_AND_OFFERS = 'setBidsAndOffers';
    static SET_ACTION_STATUS = 'setActionStatus';
    static SET_CURRENT_ORDER = 'setCurrentOrderId';
    static SET_ACTIVE_ORDER = 'setActiveOrder';
    static SET_TRADES_HISTORY = 'setTradesHistory';
    static SET_ORDER_HISTORY = 'setOrderHistory';
    static SET_ORDER_HISTORY_NULL = 'setOrderHistoryNull';
    static SET_COLLECTIONS = 'setCollections';
    static SET_TOP_COLLECTIONS = 'setTopCollections';
    static SET_COLLECTION_DETAILS = 'setCollectionDetails';
    static SET_DELIVERIES = 'setDeliveries';
    static SET_DELIVERY_DETAILS = 'setDeliveryDetails';
    static SET_SALE_ORDERS = 'setSaleOrders';
    static SET_SALE_ORDER_DETAILS = 'setSaleOrderDetails';
    static SET_COLLECTION_CART = 'setCollectionCart';
    static SET_COLLECTION_GIFT = 'setCollectionGift';
    static SET_GIFT_MESSAGE = 'setCollectionGiftMessage';
    static SET_SAVED_CC = 'setSavedCc';
    static SET_SAVED_MANGOPAY_CC = 'setSavedMangopayCc';
    static SET_GIFT_FEES = 'setGiftFees';
    static UPDATE_COLLECTION_WINE = 'udpateCollectionWine';
    static SET_CHRISTMAS_COLLECTIONS = 'setChristmasCollection';
    static SET_CHRISTMAS_COLLECTIONS2 = 'setChristmasCollection2';
}

export class MCommon {
    static SET_ALL_COUNTRIES = 'setAllCountries';
    static SET_ALL_REGIONS = 'setAllRegions';
    static SET_GIFT_COUNTRIES = 'setGiftCountries';
    static SET_MOBILE_VERSION = 'setMobileVersion';
    static SET_ALL_APPELLATIONS = 'setAllAppellations';
    static SET_SHOULD_MARKET_UPDATES = 'setShouldMarketUpdates';
    static SET_BANNERS = 'setBanners';
}

export class MMarket {
    static SET_TOTAL = 'setTotal';
    static ADD_WINES = 'addWines';
    static SET_WINES = 'setWines';
    static SET_TOP_WINES = 'setTopWines';
    static SET_TOP_EN_WINES = 'setTopEnWines';
    static SET_ACTIVE_FILTER = 'setActiveFilter';
    static SET_PRODUCERS = 'setProducers';
    static SET_SORT_FILTERS = 'setMarketSortFilters';
    static SET_PICKED_SORT_FILTERS = 'setMarketPickedSortFilters';
    static SET_OPEN_SEARCH = 'setMarketOpenSearch';
    static SET_FROM_MARKET = 'setMarketFromMarket';
}

export class MWines {
    static SET_WINE_INFO = 'setWineInfo';
    static SET_NFC_WINE_INFO = 'setNfcWineInfo';
    static SET_SELECTED_WINE = 'setSelectedWine';
    static SET_SELECTED_WINE_WAREHOUSE_NAME = 'setSelectedWineWarehouseName';
    static SET_FAVOURITE_WINES = 'setFavouriteWines';
    static SET_OFFERS_AVAILABILITY = 'setOffersAvailability';
    static SET_BARCODE_INFO = 'setBarcodeInfo';
    static SET_WINE_LOADING = 'setWineLoading';
    static SET_FAVOURITE_WINES_NULL = 'setFavouriteWinesNull';
    static SET_WINE_CHART = 'setWineChart';
}

export class MPortfolio {
    static SET_PORTFOLIO_WINE_LIST = 'setPortfolioWineList';
    static SET_PORTFOLIO_SUMMARY = 'setPortfolioSummary';
    static SET_NEW_PORTFOLIO_SUMMARY = 'setNewPortfolioSummary';
    static SET_PORTFOLIO_WINE_LIST_NULL = 'setPortfolioWineListNull';
    static SET_PORTFOLIO_WINE_LIST_LOADING = 'setPortfolioWineListLoading';
    static SET_SOLD_WINES_SUMMARY = 'setSoldWinesSummary';
    static SET_SOLD_WINES_LIST = 'setSoldWinesList';
    static SET_USER_SPECIFIC_TRANSACTIONS = 'setUserSpecificTransactions';
    static SET_CANCELLED_EXPIRED_BIDS_AND_OFFERS = 'setCancelledExpiredBidsAndOffers';
    static SET_PORTFOLIO_PERFORMANCE_ALL_CASE_SIZES = 'setPortfolioPerformanceAllCaseSizes';
}

export class MPerformance {
    static SET_REGION_LIST = 'setRegionList';
    static SET_COLOUR_LIST = 'setColourList';
    static SET_MARKET_VALUES = 'setMarketValues';
    static SET_MARKET_VALUES_BY_WINE = 'setMarketValuesByWine';
    static SET_BOTTLES = 'setBottles';
    static SET_INVESTMENTS_FILTERS = 'setInvestmentsFilters';
    static SET_BIDSOFFERS_FILTERS = 'setBidsOffersFilters';
    static SET_SOLDWINES_FILTERS = 'setSoldWinesFilters';
}
