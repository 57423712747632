import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";
import AuthApiService from "../services/authApiService";
import UserApiService from "../services/userApiService";
import MarketApiService from "../services/marketApiService";
import OrderApiService from "../services/orderApiService";
import WineApiService from "../services/wineApiService";
import PortfolioApiService from "../services/portfolioApiService";
import PerformanceApiService from "../services/performanceApiService";
import {GAuth, GCommon, GMarket, GOrders, GPortfolio, GUser, GWines, GPerformance} from "./getters";
import {MAuth, MCommon, MMarket, MOrders, MPortfolio, MUser, MWines, MPerformance} from "./mutations";
import {AAuth, ACommon, AMarket, AOrders, APortfolio, AUser, AWines, APerformance} from "./actions";
import moment from "moment";

Vue.use(Vuex, axios);

export default {
    state() {
        return {
            countries: {
                "AS": {"id": "4", "value": "American Samoa"},
                "AD": {"id": "5", "value": "Andorra"},
                "AM": {"id": "11", "value": "Armenia"},
                "AU": {"id": "13", "value": "Australia"},
                "AT": {"id": "14", "value": "Austria"},
                "BD": {"id": "18", "value": "Bangladesh"},
                "BY": {"id": "20", "value": "Belarus"},
                "BG": {"id": "261", "value": "Bulgaria"},
                "BE": {"id": "21", "value": "Belgium"},
                "BT": {"id": "21", "value": "Bhutan"},
                "HU": {"id": "262", "value": "Hungary"},
                "HR": {"id": "263", "value": "Croatia"},
                "BR": {"id": "29", "value": "Brazil"},
                "IO": {"id": "30", "value": "British Indian Ocean Territory"},
                "VG": {"id": "31", "value": "British Virgin Islands"},
                "CA": {"id": "39", "value": "Canada"},
                "CL": {"id": "44", "value": "Chile"},
                "CN": {"id": "45", "value": "China"},
                "CX": {"id": "46", "value": "Christmas Island"},
                "CO": {"id": "48", "value": "Colombia"},
                "CK": {"id": "50", "value": "Cook Islands"},
                "CY": {"id": "54", "value": "Cyprus"},
                "CZ": {"id": "55", "value": "Czech Republic"},
                "DK": {"id": "57", "value": "Denmark"},
                "EC": {"id": "61", "value": "Ecuador"},
                "SV": {"id": "63", "value": "El Salvador"},
                "EE": {"id": "66", "value": "Estonia"},
                "FO": {"id": "69", "value": "Faroe Islands"},
                "FI": {"id": "71", "value": "Finland"},
                "FR": {"id": "72", "value": "France"},
                "GE": {"id": "77", "value": "Georgia"},
                "DE": {"id": "78", "value": "Germany"},
                "GR": {"id": "81", "value": "Greece"},
                "GL": {"id": "82", "value": "Greenland"},
                "GU": {"id": "84", "value": "Guam"},
                "VA": {"id": "90", "value": "Holy See (Vatican City)"},
                "HK": {"id": "92", "value": "Hong Kong"},
                "IN": {"id": "95", "value": "India"},
                "ID": {"id": "96", "value": "Indonesia"},
                "IE": {"id": "99", "value": "Ireland"},
                "IM": {"id": "100", "value": "Isle of Man"},
                "IL": {"id": "101", "value": "Israel"},
                "IT": {"id": "102", "value": "Italy"},
                "JP": {"id": "105", "value": "Japan"},
                "JE": {"id": "106", "value": "Jersey"},
                "JO": {"id": "260", "value": "Jordan"},
                "KZ": {"id": "108", "value": "Kazakhstan"},
                "KI": {"id": "110", "value": "Kiribati"},
                "XK": {"id": "111", "value": "Kosovo"},
                "KW": {"id": "112", "value": "Kuwait"},
                "KG": {"id": "113", "value": "Kyrgyzstan"},
                "LV": {"id": "115", "value": "Latvia"},
                "LI": {"id": "120", "value": "Liechtenstein"},
                "LT": {"id": "121", "value": "Lithuania"},
                "LU": {"id": "122", "value": "Luxembourg"},
                "MY": {"id": "127", "value": "Malaysia"},
                "MT": {"id": "130", "value": "Malta"},
                "MH": {"id": "131", "value": "Marshall Islands"},
                "YT": {"id": "134", "value": "Mayotte"},
                "MX": {"id": "135", "value": "Mexico"},
                "FM": {"id": "136", "value": "Micronesia"},
                "MC": {"id": "138", "value": "Monaco"},
                "ME": {"id": "140", "value": "Montenegro"},
                "NR": {"id": "145", "value": "Nauru"},
                "NL": {"id": "147", "value": "Netherlands"},
                "NZ": {"id": "150", "value": "New Zealand"},
                "NI": {"id": "151", "value": "Nicaragua"},
                "NG": {"id": "153", "value": "Nigeria"},
                "NU": {"id": "154", "value": "Niue"},
                "NF": {"id": "155", "value": "Norfolk Island"},
                "MP": {"id": "157", "value": "Northern Mariana Islands"},
                "NO": {"id": "158", "value": "Norway"},
                "PK": {"id": "160", "value": "Pakistan"},
                "PW": {"id": "161", "value": "Palau"},
                "PE": {"id": "165", "value": "Peru"},
                "PH": {"id": "166", "value": "Philippines"},
                "PN": {"id": "167", "value": "Pitcairn Islands"},
                "PL": {"id": "168", "value": "Poland"},
                "PT": {"id": "169", "value": "Portugal"},
                "PR": {"id": "170", "value": "Puerto Rico"},
                "RU": {"id": "174", "value": "Russia"},
                "BL": {"id": "176", "value": "Saint Barthelemy"},
                "MF": {"id": "180", "value": "Saint Martin"},
                "PM": {"id": "181", "value": "Saint Pierre and Miquelon"},
                "SM": {"id": "184", "value": "San Marino"},
                "SA": {"id": "186", "value": "Saudi Arabia"},
                "SC": {"id": "256", "value": "Seychelles"},
                "SG": {"id": "191", "value": "Singapore"},
                "SK": {"id": "192", "value": "Slovakia"},
                "SI": {"id": "193", "value": "Slovenia"},
                "ZA": {"id": "196", "value": "South Africa"},
                "KR": {"id": "197", "value": "South Korea"},
                "ES": {"id": "198", "value": "Spain"},
                "LK": {"id": "199", "value": "Sri Lanka"},
                "SJ": {"id": "202", "value": "Svalbard"},
                "SE": {"id": "204", "value": "Sweden"},
                "CH": {"id": "205", "value": "Switzerland"},
                "TW": {"id": "207", "value": "Taiwan"},
                "TJ": {"id": "208", "value": "Tajikistan"},
                "TH": {"id": "210", "value": "Thailand"},
                "TL": {"id": "211", "value": "Timor-Leste"},
                "TK": {"id": "213", "value": "Tokelau"},
                "TR": {"id": "217", "value": "Turkey"},
                "TC": {"id": "219", "value": "Turks and Caicos Islands"},
                "TV": {"id": "220", "value": "Tuvalu"},
                "UA": {"id": "222", "value": "Ukraine"},
                "AE": {"id": "223", "value": "United Arab Emirates"},
                "GB": {"id": "224", "value": "United Kingdom"},
                "US": {"id": "225", "value": "United States"},
                "VI": {"id": "227", "value": "US Virgin Islands"},
                "VE": {"id": "230", "value": "Venezuela"},
                "VN": {"id": "231", "value": "Vietnam"},
                "GP": {"id": "238", "value": "Guadeloupe"},
                "MQ": {"id": "239", "value": "Martinique"},
                "RE": {"id": "240", "value": "Reunion"},
                "GF": {"id": "245", "value": "French Guiana"},
                "RO": {"id": "255", "value": "Romania"}
            },
            code: '',
            verifier: '',
            hash: '',
            access_token: '',
            addresses: [],
            userData: {},
            userNotifications: [],
            visitorCountry: '',
            commission: {
                insuranceFeeMin: 0,
                storageFee: 0,
                insuranceFeePercentage: 0,
                markup: 0,
                buyCommission: 0,
                sellCommission: 0,
                sellCommissionMin: 0,
                buyCommissionMin: 0,
                sellCommissionMax: 0,
                buyCommissionMax: 0,
                markupMin: 0,
                markupMax: 0
            },
            invoice: {},
            bankDetails: {},
            invoices: {list: [], total: 0},
            invoiceDetails: {},
            invoicesListLoading: false,
            apiUrl: 'https://api.vindome.net/api/',
            isLogged: false,
            notificationSettings: {},
            wineList: [],
            wineListLength: null,
            portfolioSummary: [],
            portfolioNewSummary: [],
            soldWinesSummary: [],
            soldWinesList: [],
            userSpecificTransactionsList: [],
            cancelledExpiredBidsAndOffersList: [],
            wineListLoading: false,
            countriesList: [],
            regionsList: [],
            giftCountries: [],
            mobileCheck: null,
            appellationsList: [],
            portfolioPerformanceAllCaseSizes: [],
            currencySign: '€',
            orders: [],
            trades: [],
            banners: [],
            orderHistory: {},
            activeOrder: {},
            fees: {},
            newOrder: {
                pricePerCase: 0,
                isBuy: true,
                barCodes: null,
                uniqueCasesIds: [],
                wineId: 0,
                matchIds: [],
                caseQuantity: 0,
                type: 0
            },
            selectedMultipleInvoicesFromInvoiceCart: [],
            collections: [],
            christmasCollections: [],
            topCollections: [],
            collectionsLength: null,
            collectionDetails: {},
            deliveries: [],
            deliveriesLength: null,
            deliveryDetails: null,
            saleOrders: [],
            saleOrdersLength: null,
            saleOrderDetails: null,
            cart: [],
            cartTotal: 0,
            gift: [],
            giftTotal: 0,
            giftMessage: null,
            savedCreditCard: null,
            wines: [],
            topWines: [],
            topEnWines: [],
            activeFilter: {},
            totalWines: 0,
            producers: [],
            sortFilters: {},
            pickedSortFilters: {
                wineIds: [],
                caseSizeIds: [],
                countryIds: [],
                regionIds: [],
                classificationsIds: [],
                producerIds: [],
                colorIds: [],
                vintages: []
            },
            openSearch: false,
            fromMarket: false,
            selectedWineDetails: {
                ratings: {},
                wine: {},
                offers: [],
                bids: [],
                portfolio: [],
                size: '',
                isLoading: true
            },
            isBuyOffersAvailable: false,
            pickedWineId: {
                wineId: 0,
                caseSize: 123,
                warehouseName: '',
                price: 0,
                units: 0
            },
            favouritesMap: new Set(),
            favourites: [],
            favouritesLength: 0,
            nfcWineDetails: null,
            regionList: [],
            regionLength: 0,
            reglionListColors: ['#E2C477', '#AC955B', '#75663E', '#E9D297', '#EFDFB6', '#EFDFB6'],
            colourList: [],
            colourListLength: 0,
            marketValuesList: [],
            marketValuesListLength: 0,
            marketValuesByWineList: [],
            bottles: [],
            bottlesListLength: 0,
            wineDetailsChart: [],
            investmentsFilters: {
                countries: [],
                colors: [],
                regions: [],
                appellations: [],
                vintages: [],
                producers: []
            },
            bidsOffersFilters: {
                countries: [],
                colours: [],
                regions: [],
                appellations: [],
                vintages: [],
                producers: [],
                types: [],
                statuses: [],
                caseSizes: []
            },
            soldWinesFilters: {
                countries: [],
                regions: [],
                appellations: [],
                vintages: [],
                colours: [],
                producers: []
            },
            mangoPayCc: null,
            declarativeData: null,
            kycStat: null,
            balance: null,
            bankAccounts: [],
            walletTransactions: {
                list: [],
                total: 0
            }
        }
    },
    getters: {
        [GAuth.GET_VERIFIER](state) {
            return state.verifier;
        },
        [GAuth.GET_VERIFIER_HASH](state) {
            return state.hash;
        },
        [GAuth.GET_TOKEN](state) {
            return state.access_token;
        },
        [GUser.GET_KYC_STATUS](state) {
            return state.kycStat
        },
        [GUser.GET_WALLET_BALANCE](state) {
            return state.balance
        },
        [GUser.GET_BANK_ACCOUNTS](state) {
            return state.bankAccounts
        },
        [GUser.GET_WALLET_TRANSACTIONS](state) {
            return state.walletTransactions
        },
        [GUser.GET_ADDRESSES](state) {
            return state.addresses || [];
        },
        [GUser.GET_USER_DATA](state) {
            return state.userData || {};
        },
        [GUser.GET_DECLARATIVE_DATA](state) {
            return state.declarativeData || null;
        },
        [GUser.GET_VISITOR_COUNTRY](state) {
            return state.visitorCountry;
        },
        [GUser.GET_USER_LOGGED](state) {
            return state.isLogged;
        },
        [GUser.GET_BANK_DETAILS](state) {
            return state.bankDetails || {};
        },
        [GUser.GET_NOTIFICATIONS](state) {
            return state.userNotifications || {}
        },
        [GUser.GET_NOTIFICATION_SETTINGS](state) {
            return state.notificationSettings || {}
        },
        [GUser.GET_USER_MAX_MARKUP_COMMISSION](state) {
            return state.commission.markupMax ? state.commission.markupMax : 0;
        },
        [GUser.GET_USER_MIN_MARKUP_COMMISSION](state) {
            return state.commission.markupMin ? state.commission.markupMin : 0;
        },
        [GUser.GET_USER_MAX_BUY_COMMISSION](state) {
            return state.commission.buyCommissionMax ? state.commission.buyCommissionMax : 0
        },
        [GUser.GET_USER_MAX_SELL_COMMISSION](state) {
            return state.commission.sellCommissionMax ? state.commission.sellCommissionMax : 0
        },
        [GUser.GET_USER_MIN_BUY_COMMISSION](state) {
            return state.commission.buyCommissionMin ? state.commission.buyCommissionMin : 0
        },
        [GUser.GET_USER_MIN_SELL_COMMISSION](state) {
            return state.commission.sellCommissionMin ? state.commission.sellCommissionMin : 0
        },
        [GUser.GET_USER_SELL_COMMISSION](state) {
            return state.commission.sellCommission ? state.commission.sellCommission : 0
        },
        [GUser.GET_USER_BUY_COMMISSION](state) {
            return state.commission.buyCommission ? state.commission.buyCommission : 0
        },
        [GUser.GET_USER_MARKUP_COMMISSION](state) {
            return state.commission.markup ? state.commission.markup : 0;
        },
        [GUser.GET_USER_INSURANCE](state) {
            return state.commission.insuranceFeePercentage;
        },
        [GUser.GET_USER_STORAGE](state) {
            return state.commission.storageFee;
        },
        [GUser.GET_USER_MIN_INSURANCE](state) {
            return state.commission.insuranceFeeMin;
        },
        [GUser.GET_INVOICES](state) {
            return state.invoices || {list: [], total: 0};
        },
        [GUser.GET_INVOICES_LOADING](state) {
            return state.invoicesListLoading;
        },
        [GUser.GET_INVOICE_DETAILS](state) {
            return state.invoiceDetails || {};
        },
        [GUser.GET_SELECTED_MULTIPLE_INVOICES_FROM_INVOICE_CART](state) {
            return state.selectedMultipleInvoicesFromInvoiceCart || [];
        },
        [GPortfolio.GET_PORTFOLIO_WINE_LIST](state) {
            return state.wineList;
        },
        [GPortfolio.GET_PORTFOLIO_SUMMARY](state) {
            return state.portfolioSummary;
        },
        [GPortfolio.GET_NEW_PORTFOLIO_SUMMARY](state) {
            return state.portfolioNewSummary;
        },
        [GPortfolio.GET_PORTFOLIO_WINE_LIST_LENGTH](state) {
            return state.wineListLength;
        },
        [GPortfolio.GET_SOLD_WINES_SUMMARY](state) {
            return state.soldWinesSummary;
        },
        [GPortfolio.GET_SOLD_WINES_LIST](state) {
            return state.soldWinesList;
        },
        [GPortfolio.GET_USER_SPECIFIC_TRANSACTIONS](state) {
            return state.userSpecificTransactionsList;
        },
        [GPortfolio.GET_CANCELLED_EXPIRED_BIDS_AND_OFFERS](state) {
            return state.cancelledExpiredBidsAndOffersList;
        },
        [GPortfolio.GET_PORTFOLIO_TOTAL_PROFIT](state) {
            let profit = 0;

            state.portfolioSummary.forEach(item => {
                profit += item.owned * item.gain
            });

            return profit;
        },
        [GPortfolio.GET_PORTFOLIO_VALUE](state, getters) {
            let portfolioValue = 0;

            state.portfolioSummary.forEach(item => {
                portfolioValue += item.owned * item.averageBuyPrice;
            });

            return portfolioValue;
        },
        [GPortfolio.GET_PORTFOLIO_WINE_LIST_LOADING](state) {
            return state.wineListLoading;
        },
        [GPortfolio.GET_PORTFOLIO_PERFORMANCE_ALL_CASE_SIZES](state) {
            return state.portfolioPerformanceAllCaseSizes;
        },
        [GCommon.GET_COUNTRIES_LIST](state) {
            return state.countriesList;
        },
        [GCommon.GET_BANNERS](state) {
            return state.banners;
        },
        [GCommon.GET_REGIONS_LIST](state) {
            return state.regionsList;
        },
        [GCommon.GET_GIFT_COUNTRIES](state) {
            return state.giftCountries;
        },
        [GCommon.GET_MOBILE_VERSION](state) {
            return state.mobileCheck;
        },
        [GCommon.GET_APPELLATIONS_LIST](state) {
            return state.appellationsList;
        },
        [GCommon.GET_CURRENCY_SIGN](state) {
            return state.currencySign;
        },
        [GOrders.GET_BIDS_AND_OFFERS](state) {
            return state.orders;
        },
        [GOrders.GET_ACTIVE_ORDER](state) {
            return state.activeOrder;
        },
        [GOrders.GET_SAVED_CC](state) {
            return state.savedCreditCard;
        },
        [GOrders.GET_SAVED_MANGOPAY_CC](state) {
            return state.mangoPayCc;
        },
        [GOrders.NEW_ORDER](state) {
            return state.newOrder || {};
        },
        [GOrders.GET_TRADES_HISTORY](state) {
            return state.trades;
        },
        [GOrders.GET_ORDER_HISTORY](state) {
            return state.orderHistory;
        },
        [GOrders.GET_COLLECTIONS](state) {
            return state.collections;
        },
        [GOrders.GET_CHRISTMAS_COLLECTIONS](state) {
            return state.christmasCollections;
        },
        [GOrders.GET_TOP_COLLECTIONS](state) {
            return state.topCollections;
        },
        [GOrders.GET_COLLECTIONS_LENGTH](state) {
            return state.collectionsLength;
        },
        [GOrders.GET_COLLECTION_DETAILS](state) {
            return state.collectionDetails;
        },
        [GOrders.GET_DELIVERIES](state) {
            return state.deliveries;
        },
        [GOrders.GET_DELIVERIES_LENGTH](state) {
            return state.deliveriesLength;
        },
        [GOrders.GET_DELIVERY_DETAILS](state) {
            return state.deliveryDetails;
        },
        [GOrders.GET_SALE_ORDERS](state) {
            return state.saleOrders;
        },
        [GOrders.GET_SALE_ORDERS_LENGTH](state) {
            return state.saleOrdersLength;
        },
        [GOrders.GET_SALE_ORDER_DETAILS](state) {
            return state.saleOrderDetails;
        },
        [GOrders.GET_CART_ITEMS](state) {
            return state.cart;
        },
        [GOrders.GET_CART_ITEMS_TOTAL](state) {
            return state.cartTotal;
        },
        [GOrders.GET_GIFT_ITEMS](state) {
            return state.gift;
        },
        [GOrders.GET_GIFT_ITEMS_TOTAL](state) {
            return state.giftTotal;
        },
        [GOrders.GET_GIFT_MESSAGE](state) {
            return state.giftMessage;
        },
        [GOrders.GET_GIFT_FEES](state) {
            return state.fees;
        },
        [GMarket.GET_OPEN_SEARCH](state) {
            return state.openSearch;
        },
        [GMarket.GET_FROM_MARKET](state) {
            return state.fromMarket;
        },
        [GMarket.GET_PRODUCERS](state) {
            return state.producers;
        },
        [GMarket.GET_TOTAL](state) {
            return state.totalWines;
        },
        [GMarket.GET_WINES](state) {
            return state.wines;
        },
        [GMarket.GET_TOP_WINES](state) {
            return state.topWines;
        },
        [GMarket.GET_TOP_EN_WINES](state) {
            return state.topEnWines;
        },
        [GMarket.GET_ACTIVE_FILTER](state) {
            let _name = '';

            switch (state.activeFilter.filter) {
                case 'ShowPopular':
                    _name = 'Most Popular';
                    break;
                case 'ShowOnlyLastTraded':
                    _name = 'Last Traded';
                    break;
                case 'ShowLatestPrices':
                    _name = 'Last price change';
                    break;
                case 'ShowOnlyTigtestSpread':
                    _name = 'Tightest spread';
                    break;
                default:
                    _name = 'Market';
                    break;
            }

            if (state.pickedSortFilters.classificationsIds.length === 1) {
                switch (state.pickedSortFilters.classificationsIds[0]) {
                    case 9:
                        _name = 'Bordeaux First Growth';
                        break;
                    case 2:
                        _name = 'Burgundy Grand Cru';
                        break;
                    default:
                        _name = 'Market';
                        break;
                }
            }
            return _name;
        },
        [GMarket.GET_SORT_FILTERS](state) {
            return state.sortFilters;
        },
        [GMarket.GET_PICKED_SORT_FILTERS](state) {
            return state.pickedSortFilters;
        },
        [GWines.GET_BARCODE_INFO](state) {
            return state.nfcWineDetails;
        },
        [GWines.GET_WAREHOUSE_NAME](state) {
            return state.pickedWineId.warehouseName;
        },
        [GWines.GET_SELECTED_WINE_CASE](state) {
            return state.pickedWineId.caseSize;
        },
        [GWines.GET_SELECTED_WINE_PRICE](state) {
            return state.pickedWineId.price;
        },
        [GWines.GET_SELECTED_WINE_UNITS](state) {
            return state.pickedWineId.units;
        },
        [GWines.IS_BUY_OFFERS_AVAILABLE](state) {
            return state.selectedWineDetails.offers.filter(el => el.buyOrderId).length
        },
        [GWines.IS_SELL_OFFERS_AVAILABLE](state) {
            return state.selectedWineDetails.offers.filter(el => el.sellOrderId).length
        },
        [GWines.GET_WINE_PORTFOLIO](state) {
            return state.selectedWineDetails.portfolio;
        },
        [GWines.GET_SELECTED_WINE_INFO](state) {
            return state.selectedWineDetails;
        },
        [GWines.GET_FAVOURITE_WINES](state) {
            return state.favourites;
        },
        [GWines.GET_FAVOURITE_WINES_LENGTH](state) {
            return state.favouritesLength;
        },
        [GWines.GET_IS_WINE_FAVOURITE]: state => wineId => {
            return state.favouritesMap.has(wineId.wineId.toString() + '' + wineId.caseSizeId.toString());
        },
        [GWines.GET_WINE_CHART](state) {
            return state.wineDetailsChart;
        },
        [GPerformance.GET_REGION_LIST](state) {
            return state.regionList;
        },
        [GPerformance.GET_COLOUR_LIST](state) {
            return state.colourList;
        },
        [GPerformance.GET_MARKET_VALUES](state) {
            return state.marketValuesList;
        },
        [GPerformance.GET_MARKET_VALUES_BY_WINE](state) {
            return state.marketValuesByWineList;
        },
        [GPerformance.GET_BOTTLES](state) {
            return state.bottles;
        },
        [GPerformance.GET_INVESTMENTS_FILTERS](state) {
            return state.investmentsFilters;
        },
        [GPerformance.GET_BIDSOFFERS_FILTERS](state) {
            return state.bidsOffersFilters;
        },
        [GPerformance.GET_SOLDWINES_FILTERS](state) {
            return state.soldWinesFilters;
        },
    },
    mutations: {
        [MAuth.SET_VERIFIER](state, verifier) {
            state.verifier = verifier;
        },
        [MAuth.SET_VERIFIER_HASH](state, hash) {
            state.hash = hash;
        },
        [MAuth.SET_ACCESS_TOKEN](state, token) {
            state.access_token = token;
        },
        [MAuth.SET_CODE](state, code) {
            state.code = code;
        },
        [MUser.SET_SELECTED_MULTIPLE_INVOICES_FROM_INVOICE_CART](state, ids) {
            state.selectedMultipleInvoicesFromInvoiceCart = ids;
        },
        [MUser.SET_USER_COMMISSION](state, commission) {
            state.commission = commission
        },
        [MUser.SET_ADDRESSES](state, addresses) {
            state.addresses = addresses
        },
        [MUser.SET_BANK_DETAILS](state, bankDetails) {
            state.bankDetails = bankDetails
        },
        [MUser.SET_USER_DATA](state, data) {
            state.userData = data
        },
        [MUser.SET_VISITOR_COUNTRY](state, data) {
            state.visitorCountry = data
        },
        [MUser.SET_USER_LOGGED](state, data) {
            state.isLogged = data
        },
        [MUser.SET_INVOICES](state, invoicesList) {
            state.invoices = invoicesList
        },
        [MUser.SET_DECLARATIVE_DATA] (state, userDeclarativeData) {
            state.declarativeData = userDeclarativeData
        },
        [MUser.SET_KYC_STATUS] (state, kycStatus) {
            state.kycStat = kycStatus
        },
        [MUser.SET_BANK_ACCOUNTS] (state, banks) {
            state.bankAccounts = banks
        },
        [MUser.SET_WALLET_BALANCE] (state, balance) {
            state.balance = balance.toFixed(2)
        },
        [MUser.SET_WALLET_TRANSACTIONS] (state, data) {
            state.walletTransactions.list = state.walletTransactions.list.concat(data.list)
            state.walletTransactions.total = data.total
        },
        [MUser.RESET_WALLET_TRANSACTIONS] (state) {
            state.walletTransactions.list = []
            state.walletTransactions.total = 0
        },
        [MUser.SET_INVOICE_DETAILS](state, invoiceItem) {
            state.invoiceDetails = invoiceItem
        },
        [MUser.SET_INVOICES_LOADING](state, status) {
            state.invoicesListLoading = status;
        },
        [MUser.SET_NOTIFICATIONS](state, items) {
            state.userNotifications = items
        },
        [MUser.SET_NOTIFICATION_SETTINGS](state, items) {
            state.notificationSettings = items
        },
        [MPortfolio.SET_PORTFOLIO_WINE_LIST](state, list) {
            state.wineList = list;
            state.wineListLength = list.length;
        },
        [MPortfolio.SET_PORTFOLIO_WINE_LIST_NULL](state) {
            state.wineList = [];
            state.wineListLength = null;
        },
        [MPortfolio.SET_PORTFOLIO_SUMMARY](state, list) {
            state.portfolioSummary = list;
        },
        [MPortfolio.SET_NEW_PORTFOLIO_SUMMARY](state, list) {
            state.portfolioNewSummary = list;
        },
        [MPortfolio.SET_SOLD_WINES_SUMMARY](state, list) {
            state.soldWinesSummary = list;
        },
        [MPortfolio.SET_SOLD_WINES_LIST](state, list) {
            state.soldWinesList = list;
        },
        [MPortfolio.SET_USER_SPECIFIC_TRANSACTIONS](state, list) {
            state.userSpecificTransactionsList = list;
        },
        [MPortfolio.SET_CANCELLED_EXPIRED_BIDS_AND_OFFERS](state, list) {
            state.cancelledExpiredBidsAndOffersList = list;
        },
        [MPortfolio.SET_PORTFOLIO_WINE_LIST_LOADING](state, status) {
            state.wineListLoading = status;
        },
        [MPortfolio.SET_PORTFOLIO_PERFORMANCE_ALL_CASE_SIZES](state, list) {
            state.portfolioPerformanceAllCaseSizes = list;
        },
        [MCommon.SET_ALL_COUNTRIES](state, list) {
            state.countriesList = list;
        },
        [MCommon.SET_BANNERS](state, list) {
            state.banners = list;
        },
        [MCommon.SET_MOBILE_VERSION](state, isMobile) {
            state.mobileCheck = isMobile;
        },
        [MCommon.SET_ALL_REGIONS](state, list) {
            state.regionsList = list;
        },
        [MCommon.SET_GIFT_COUNTRIES](state, list) {
            state.giftCountries = list;
        },
        [MCommon.SET_ALL_APPELLATIONS](state, list) {
            state.appellationsList = list;
        },
        [MOrders.SET_ACTIVE_ORDER](state, order) {
            state.activeOrder = order;
        },
        [MOrders.SET_BIDS_AND_OFFERS](state, orders) {
            state.orders = orders;
        },
        [MOrders.SET_CURRENT_ORDER](state, order) {
            state.newOrder = order;
        },
        [MOrders.SET_TRADES_HISTORY](state, trades) {
            state.trades = state.trades.concat(trades);
        },
        [MOrders.SET_ORDER_HISTORY_NULL](state) {
            state.trades = [];
        },
        [MOrders.SET_ORDER_HISTORY](state, order) {
            state.orderHistory = order;
        },
        [MOrders.SET_SAVED_CC](state, savedCC) {
            state.savedCreditCard = savedCC;
        },
        [MOrders.SET_SAVED_MANGOPAY_CC](state, data) {
            state.mangoPayCc = data;
        },
        [MOrders.SET_COLLECTIONS](state, collections) {
            state.collections = collections.list;
            state.collectionsLength = collections.total;
        },
        [MOrders.SET_CHRISTMAS_COLLECTIONS](state, collections) {
            state.christmasCollections = collections;
        },
        [MOrders.SET_CHRISTMAS_COLLECTIONS2](state, collections) {
            let tempArr = collections.filter(el => el.isGiftDelivery && el.availableQuantity > 0);

            state.christmasCollections = tempArr.reduce((acc, curr) => {
                const wineId = curr.wines[0].wineId;
                const existingItem = acc.find(item => item.wines[0].wineId === wineId);
                if (!existingItem) {
                    curr.wines = curr.wines.map(wine => ({
                        ...wine,
                        collectionId: curr.id,
                        collectionPrice: curr.price,
                        collectionOrderIds: curr.collectionOrderIds
                    }));
                    curr.selectedCollectionId = curr.wines[0].collectionId
                    acc.push(curr);
                } else {
                    const newWines = curr.wines.map(wine => ({
                        ...wine,
                        collectionId: curr.id,
                        collectionPrice: curr.price,
                        collectionOrderIds: curr.collectionOrderIds
                    }));
                    existingItem.wines = [...existingItem.wines, ...newWines];
                }
                return acc;
            }, []);
        },
        [MOrders.SET_TOP_COLLECTIONS](state, collections) {
            state.topCollections = collections.collections;
        },
        [MOrders.SET_COLLECTION_DETAILS](state, collection) {
            state.collectionDetails = collection.list[0];
        },
        [MOrders.SET_DELIVERIES](state, deliveries) {
            state.deliveries = deliveries.list;
            state.deliveriesLength = deliveries.total;
        },
        [MOrders.SET_DELIVERY_DETAILS](state, deliveryDetails) {
            state.deliveryDetails = deliveryDetails.list;
        },
        [MOrders.SET_SALE_ORDERS](state, orders) {
            state.saleOrders = orders.list;
            state.saleOrdersLength = orders.total;
        },
        [MOrders.SET_SALE_ORDER_DETAILS](state, saleOrderDetails) {
            state.saleOrderDetails = saleOrderDetails;
        },
        [MOrders.SET_COLLECTION_CART](state, data) {
            if (data.resetCart) {
                state.cart = [];
                state.cartTotal = 0;

                if (process.client) {
                    localStorage.removeItem('basket_items');
                }
            } else {
                if (data.fromCounter) {
                    let collectionIndex = -1;
                    state.cart.forEach((element, index) => {
                        if (element.id === data.collectionId) {
                            collectionIndex = index;
                        }
                    });

                    state.cart[collectionIndex].unitsCount = data.counterVal;

                    let totalCount = 0;

                    state.cart.forEach(element => {
                        totalCount += element.unitsCount;
                    });

                    state.cartTotal = totalCount;
                } else {
                    if (data.addRemove) {
                        let collectionIndex = -1;
                        state.cart.forEach((element, index) => {
                            if (element.id === data.collections.id) {
                                collectionIndex = index;
                            }
                        });

                        state.cartTotal = state.cartTotal + data.collections.unitsCount;

                        if (collectionIndex === -1 || data.collections.christmasGift) {
                            state.cart = state.cart.concat(data.collections);
                        } else {
                            state.cart[collectionIndex].unitsCount =
                                state.cart[collectionIndex].unitsCount +
                                data.collections.unitsCount;
                        }
                    } else {
                        let collectionIndex = -1;
                        state.cart.forEach((element, index) => {
                            if (element.id === data.collectionId) {
                                collectionIndex = index;
                            }
                        });

                        state.cartTotal =
                            state.cartTotal -
                            state.cart[collectionIndex].unitsCount;
                        state.cart.splice(collectionIndex, 1);
                    }
                }

                if (state.cartTotal) {
                    if (process.client) {
                        localStorage.setItem('basket_items', JSON.stringify(state.cart));
                    }
                } else {
                    if (process.client) {
                        localStorage.removeItem('basket_items');
                    }
                }
            }
        },
        [MOrders.SET_COLLECTION_GIFT](state, data) {
            if (data.resetCart) {
                state.gift = [];
                state.giftTotal = 0;
            } else {
                if (data.fromCounter) {
                    let collectionIndex = -1;
                    state.gift.forEach((element, index) => {
                        if (element.id === data.collectionId) {
                            collectionIndex = index;
                        }
                    });
                    state.gift[collectionIndex].unitsCount = data.counterVal;

                    let totalCount = 0;

                    state.gift.forEach(element => {
                        totalCount += element.unitsCount;
                    });

                    state.giftTotal = totalCount;
                } else {
                    if (data.addRemove) {
                        let collectionIndex = -1;
                        state.gift.forEach((element, index) => {
                            if (element.id === data.collections.id) {
                                collectionIndex = index;
                            }
                        });

                        state.giftTotal =
                            state.giftTotal + data.collections.unitsCount;

                        if (collectionIndex === -1) {
                            state.gift = state.gift.concat(data.collections);
                        } else {
                            state.gift[collectionIndex].unitsCount =
                                state.gift[collectionIndex].unitsCount +
                                data.collections.unitsCount;
                        }
                    } else {
                        let collectionIndex = -1;
                        state.gift.forEach((element, index) => {
                            if (element.id === data.collectionId) {
                                collectionIndex = index;
                            }
                        });

                        state.giftTotal =
                            state.giftTotal -
                            state.gift[collectionIndex].unitsCount;
                        state.gift.splice(collectionIndex, 1);
                    }
                }
            }
        },
        [MOrders.SET_GIFT_MESSAGE](state, data) {
            state.giftMessage = data;
        },
        [MOrders.SET_GIFT_FEES](state, data) {
            state.fees = data;
        },
        [MOrders.UPDATE_COLLECTION_WINE](state, { collectionIndex, selectedCollectionId, price }) {
            const collection = state.christmasCollections[collectionIndex];
            console.warn(collection)
            if (collection) {
                collection.selectedCollectionId = selectedCollectionId;
                collection.price = price;
            }
        },
        [MMarket.SET_OPEN_SEARCH](state, openSearch) {
            state.openSearch = openSearch;
        },
        [MMarket.SET_FROM_MARKET](state, fromMarket) {
            state.fromMarket = fromMarket;
        },
        [MMarket.SET_PRODUCERS](state, producers) {
            state.producers = producers;
        },
        [MMarket.ADD_WINES](state, wines) {
            state.wines = wines;
        },
        [MMarket.SET_WINES](state, wines) {
            state.wines = wines;
        },
        [MMarket.SET_TOP_WINES](state, wines) {
            state.topWines = wines;
        },
        [MMarket.SET_TOP_EN_WINES](state, wines) {
            state.topEnWines = wines;
        },
        [MMarket.SET_ACTIVE_FILTER](state, filter) {
            state.activeFilter = filter;
        },
        [MMarket.SET_TOTAL](state, total) {
            state.totalWines = total;
        },
        [MMarket.SET_SORT_FILTERS](state, filters) {
            state.sortFilters = filters;
        },
        [MMarket.SET_PICKED_SORT_FILTERS](state, {key, filters}) {
            if (key === undefined) state.pickedSortFilters = filters;
            else state.pickedSortFilters[key] = filters;
        },
        [MWines.SET_SELECTED_WINE_WAREHOUSE_NAME](state, warehouseName) {
            state.pickedWineId.warehouseName = warehouseName;
        },
        [MWines.SET_OFFERS_AVAILABILITY](state, availability) {
            state.isOffersAvailable = availability;
        },
        [MWines.SET_WINE_INFO](state, wine) {
            state.selectedWineDetails = wine;
            state.selectedWineDetails.ratings = wine.isLogged ? wine.wine.ratings : wine.ratings;
            state.selectedWineDetails.isLoading = false
        },
        [MWines.SET_NFC_WINE_INFO](state, wine) {
            state.nfcWineDetails = wine;
        },
        [MWines.SET_SELECTED_WINE](state, id) {
            state.pickedWineId = Object.assign({}, id);
        },
        [MWines.SET_FAVOURITE_WINES](state, winesList) {
            state.favouritesMap.clear();
            state.favourites = winesList;
            state.favouritesLength = winesList.length;

            winesList.forEach(wine => {
                state.favouritesMap.add(wine.wineId.toString() + '' + wine.caseSizeId.toString());
            });
        },
        [MWines.SET_FAVOURITE_WINES_NULL](state) {
            state.favourites = [];
            state.favouritesLength = 0;
        },
        [MWines.SET_WINE_LOADING](state) {
            state.selectedWineDetails.isLoading = true;
        },
        [MWines.SET_WINE_CHART](state, wine) {
            state.wineDetailsChart = wine;
        },
        [MPerformance.SET_REGION_LIST](state, list) {
            state.regionList = list;
            state.regionLength = list.length;
        },
        [MPerformance.SET_COLOUR_LIST](state, list) {
            state.colourList = list;
            state.colourListLength = list.length;
        },
        [MPerformance.SET_MARKET_VALUES](state, list) {
            state.marketValuesList = list;
            state.marketValuesListLength = list.length;
        },
        [MPerformance.SET_MARKET_VALUES_BY_WINE](state, list) {
            state.marketValuesByWineList = list;
        },
        [MPerformance.SET_BOTTLES](state, list) {
            state.bottles = list;
            state.bottlesListLength = list.length;
        },
        [MPerformance.SET_INVESTMENTS_FILTERS](state, filters) {
            state.investmentsFilters[filters.propName] = filters.list
        },
        [MPerformance.SET_BIDSOFFERS_FILTERS](state, filters) {
            state.bidsOffersFilters[filters.propName] = filters.list
        },
        [MPerformance.SET_SOLDWINES_FILTERS](state, filters) {
            state.soldWinesFilters[filters.propName] = filters.list
        },
    },
    actions: {
        [AAuth.GET_TOKEN]({commit}, {email, pass, browser, device}) {
            let payload = {
                grant_type: 'password',
                username: email,
                password: pass,
                scope: 'openid profile apiApp offline_access',
                client_id: 'app.native',
                browser: browser,
                device: device
            };
            return AuthApiService.instance().signin(payload).then((res) => {
                let token = res.access_token;
                if (process.client) {
                    localStorage.setItem('access_token', token);
                }
                commit(MAuth.SET_ACCESS_TOKEN, token);
                return res
            });
        },
        [AAuth.GET_CONFIRMED]({commit}, email) {
            return AuthApiService.instance().checkConfirmation(email).then((res) => {
                return res
            });
        },
        [AAuth.REG_USER]({commit}, payload) {
            return AuthApiService.instance().signup(payload).then((res) => {
                return res
            });
        },
        [AAuth.LOGIN_EXTERNAL_USER]({commit}, fbToken) {
            return AuthApiService.instance().regExternal(fbToken).then((res) => {
                return res
            });
        },
        [AAuth.LOGIN_GOOGLE_USER]({commit}, googleToken) {
            return AuthApiService.instance().regGoogle(googleToken).then((res) => {
                return res
            });
        },
        [AAuth.LOGIN_APPLE_USER]({commit}, appleData) {
            return AuthApiService.instance().regApple(appleData).then((res) => {
                return res
            });
        },
        [AAuth.SEND_MAIL]({commit}, emailAddress) {
            return AuthApiService.instance().sendMail(emailAddress).then((res) => {
                return res
            });
        },
        [AAuth.LOG_OUT]({commit}) {
            commit(MPortfolio.SET_PORTFOLIO_WINE_LIST, []);
            if (process.client) {
                localStorage.removeItem('access_token');
            }
            commit(MAuth.SET_ACCESS_TOKEN, undefined);
        },
        [AAuth.SET_VERIFIER](context, verifier) {
            context.commit(MAuth.SET_VERIFIER, verifier);
        },
        [AAuth.SET_VERIFIER_HASH](context, hash) {
            context.commit(MAuth.SET_VERIFIER_HASH, hash);
        },
        [AAuth.SET_CODE](context, code) {
            context.commit(MAuth.SET_CODE, code);
        },
        [AAuth.SET_TOKEN](context, token) {
            context.commit(MAuth.SET_ACCESS_TOKEN, token);
        },
        [AUser.SET_SELECTED_MULTIPLE_INVOICES_FROM_INVOICE_CART]({commit}, ids) {
            commit(MUser.SET_SELECTED_MULTIPLE_INVOICES_FROM_INVOICE_CART, ids);
        },
        async [AUser.GET_USER_COMMISSION]({commit, state}) {
            let commission = await UserApiService.instance().getUserCommission();
            commit(MUser.SET_USER_COMMISSION, commission);
        },
        async [AUser.ADD_BANK_DETAILS]({commit, state, rootState}, bankDetails) {
            return UserApiService.instance().addBankDetails(bankDetails)
        },
        async [AUser.VALIDATE_BANK_DETAILS]({commit, state, rootState}, bankDetails) {
            return await UserApiService.instance().validateBankDetails(bankDetails)
        },
        async [AUser.UPDATE_BANK_DETAILS]({commit, state, rootState}, bankDetails) {
            if (process.client) {
                let token = await localStorage.getItem('access_token'),
                    headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    url = state.apiUrl + 'bankdetails';

                return new Promise((resolve, reject) => {
                    axios.put(url, bankDetails, {headers: headers}).then(() => resolve()).catch(() => reject());
                });
            }
        },
        async [AUser.UPDATE_ADDRESS]({commit, state, rootState}, address) {
            return UserApiService.instance().updateAddress(address);
        },
        async [AUser.ADD_ADDRESS]({commit, state, rootState}, address) {
            return UserApiService.instance().addAddress(address);
        },
        async [AUser.GET_PROMOCODES]({commit, state, rootState}, promo) {
            return UserApiService.instance().getPromos(promo);
        },
        async [AUser.DELETE_ADDRESS]({commit, state, rootState}, address) {
            if (process.client) {
                let token = await localStorage.getItem('access_token'),
                    headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    };

                let url = state.apiUrl + 'Address?addressId=' + address.id;

                return new Promise((resolve, reject) => {
                    axios.delete(url, {headers: headers}).then(() => resolve()).catch(() => reject())
                })
            }
        },
        async [AUser.GET_ADDRESSES]({commit, state}) {
            if (process.client) {
                let token = await localStorage.getItem('access_token');
                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                };
                axios.get(`${state.apiUrl}Address`, {headers: headers}).then(r => {
                    commit(MUser.SET_ADDRESSES, r.data)
                }).catch(() => {
                    commit(MUser.SET_ADDRESSES, [])
                });
            }
        },
        async [AUser.RESET_ADDRESSES]({commit}) {
            commit(MUser.SET_ADDRESSES, [])
        },
        async [AUser.GET_BANK_DETAILS]({commit}) {
            let bankDetails = await UserApiService.instance().getBankDetails();
            commit(MUser.SET_BANK_DETAILS, bankDetails)
        },
        async [AUser.GET_2FA_CONFIRMED]() {
            return UserApiService.instance().get2FAConfirmed()
        },
        async [AUser.GET_2FA_EMAIL]() {
            return UserApiService.instance().get2FAEmail()
        },
        async [AUser.SET_2FA_CODE]({ commit, state, rootState }, code) {
            return UserApiService.instance().send2faCode(code)
        },
        async [AUser.WITHDRAW_MONEY]({ commit, state, rootState }, amount) {
            return UserApiService.instance().withdrawMoney(amount)
        },
        async [AUser.RESET_BANK_DETAILS]({commit}) {
            commit(MUser.SET_BANK_DETAILS, {})
        },
        [AUser.SET_USER_LOGGED]({commit}, isLogged) {
            commit(MUser.SET_USER_LOGGED, isLogged)
        },
        async [AUser.GET_USER_DATA]({commit}) {
            let userData = await UserApiService.instance().getUserData();

            userData.fullName = `${userData.firstName} ${userData.lastName}`;
            commit(MUser.SET_USER_DATA, userData)
        },
        [AUser.SAVE_USER_DATA]({commit, state, dispatch}, userObj) {
            return UserApiService.instance().saveUserData(userObj);
        },
        [AUser.CHANGE_PASS]({commit, dispatch, state}, {oldPass, newPass}) {
            let payload = {
                userName: state.userData.email,
                oldPassword: oldPass,
                newPassword: newPass
            };
            return UserApiService.instance().changePass(payload)
        },
        [AUser.CONFIRM_EMAIL]({state, dispatch}) {
            return UserApiService.instance().confirmEmail({email: state.userData.email}).then(() => {
                dispatch(ANotification.ADD_NOTIFICATION, {message: 'Check your email!'})
            });
        },
        async [AUser.GET_VISITOR_COUNTRY]({commit}) {
            axios.get(`https://api.vindome.net/api/Ip`)
                .then(response => {
                    commit(MUser.SET_VISITOR_COUNTRY, response.data)
                })
                .catch(err => {
                    commit(MUser.SET_VISITOR_COUNTRY, []);
                });
        },
        async [AUser.GET_INVOICES]({commit}) {
            commit(MUser.SET_INVOICES_LOADING, true)
            return UserApiService.instance().getInvoice().then(res => {
                commit(MUser.SET_INVOICES, res)
                commit(MUser.SET_INVOICES_LOADING, false)
            });
        },
        async [AUser.GET_DECLARATIVE_DATA]({ commit }) {
            return UserApiService.instance().getDeclarativeData().then(res => {
                commit(MUser.SET_DECLARATIVE_DATA, res)
            })
        },
        async [AUser.GET_KYC_STATUS]({ commit }) {
            return UserApiService.instance().getKycStatus().then(res => {
                commit(MUser.SET_KYC_STATUS, res.status)
            })
        },
        async [AUser.GET_BANK_ACCOUNTS]({ commit }) {
            return UserApiService.instance().getBankAccounts().then(res => {
                commit(MUser.SET_BANK_ACCOUNTS, res)
            })
        },
        async [AUser.GET_WALLET_BALANCE]({ commit }) {
            return UserApiService.instance().getWalletBalance().then(res => {
                commit(MUser.SET_WALLET_BALANCE, res)
            })
        },
        async [AUser.GET_WALLET_TRANSACTIONS]({ commit }, page) {
            return UserApiService.instance().getWalletTransactions(page).then(res => {
                commit(MUser.SET_WALLET_TRANSACTIONS, res)
            })
        },
        async [AUser.UPDATE_DECLARATIVE_DATA]({ commit }, data) {
            return UserApiService.instance().updateDeclarativeData(data)
        },
        async [AUser.UPLOAD_DOCUMENT_ONE]({ commit }, data) {
            return UserApiService.instance().uploadDocumentOne(data)
        },
        async [AUser.UPLOAD_DOCUMENT_TWO]({ commit }, data) {
            return UserApiService.instance().uploadDocumentTwo(data)
        },
        async [AUser.UPLOAD_DOCUMENT_SUBMIT]({ commit }) {
            return UserApiService.instance().uploadDocumentSubmit()
        },
        async [AUser.GET_INVOICE_DETAILS]({commit, state, rootState}, data) {
            return UserApiService.instance().getInvoiceDetails({
                ids: data.invoiceId,
                invoiceType: data.invoiceType
            }).then(res => {
                commit(MUser.SET_INVOICE_DETAILS, res)
            });
        },
        async [AUser.GET_INVOICE_PDF]({commit, state, rootState}, {invoiceId, invoiceType, isGift, userId}) {
            return UserApiService.instance().getInvoicePDF(invoiceId, invoiceType, isGift, userId)
        },
        async [AUser.GET_INVOICE_PAYMENT]({commit, state, rootState}, data) {
            return UserApiService.instance().getInvoicePayment({
                ids: data.invoiceId,
                shouldUseSavedCard: data.isSaved,
                pspType: data.pspType,
                cardId: data.cardId || null,
                browserInfo: data.browserInfo || null,
                tokenData: data.tokenData || null,
            })
        },
        async [AUser.GET_NOTIFICATIONS]({commit}) {
            return UserApiService.instance().getNotifications().then(res => {
                commit(MUser.SET_NOTIFICATIONS, res.list)
            })
        },
        async [AUser.DELETE_NOTIFICATION]({commit, state, rootState}, id) {
            return UserApiService.instance().delNotification(id)
        },
        async [AUser.GET_NOTIFICATION_SETTINGS]({commit}) {
            return UserApiService.instance().getNotificationSettings().then(res => {
                commit(MUser.SET_NOTIFICATION_SETTINGS, res)
            })
        },
        async [AUser.SAVE_NOTIFICATION_SETTINGS]({commit, state, rootState}, notificationSettings) {
            return UserApiService.instance().saveNotificationSettings(notificationSettings)
        },
        async [AUser.SET_NOTIFICATIONS_READ]() {
            return UserApiService.instance().resetNotifications()
        },
        async [AUser.SET_SURVEY]({commit, state, rootState}, answers) {
            return UserApiService.instance().saveAnswers(answers)
        },
        [APortfolio.GET_PORTFOLIO]({dispatch}) {
            dispatch(APortfolio.LOAD_WINES);
        },
        [APortfolio.REMOVE_WINES]({commit}) {
            return commit(MPortfolio.SET_PORTFOLIO_WINE_LIST_NULL);
        },
        [APortfolio.LOAD_WINES]({commit, state}, obj = {}) {
            if (process.client) {
                commit(MPortfolio.SET_PORTFOLIO_WINE_LIST_LOADING, true)
                PortfolioApiService.instance().getPortfolio({
                    from: 0,
                    to: 1500,
                    statusId: obj?.statusId ? 1 : null,
                    vintageId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.vintageId ? obj.filtersObj.vintageId : null) : null,
                    countryId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.countryId ? obj.filtersObj.countryId : null) : null,
                    regionId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.regionId ? obj.filtersObj.regionId : null) : null,
                    appellationId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.appellationId ? obj.filtersObj.appellationId : null) : null,
                    wineId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.wineId ? obj.filtersObj.wineId : null) : null,
                    producerId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.producerId ? obj.filtersObj.producerId : null) : null,
                    colorId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.colorId ? obj.filtersObj.colorId : null) : null,
                    isEnPrimeur: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.isEnPrimeur ? obj.filtersObj.isEnPrimeur : null) : null
                }).then(res => {
                    let newWineList = res.list.map(item => {
                        item.lastTradePrice = item.wine.lastTradePrice;
                        item.percentageDiffernece = (Math.random() * (100 - -100) + -100).toFixed(1)
                        item.wine.size = item.size;
                        item.wine.date = moment(item.dateOn).format('DD.MM.YYYY H:mm')
                        return item;
                    });
                    commit(MPortfolio.SET_PORTFOLIO_WINE_LIST, newWineList)
                    commit(MPortfolio.SET_PORTFOLIO_WINE_LIST_LOADING, false)
                });
            }
        },
        [APortfolio.GET_PORTFOLIO_SUMMARY]({commit, state}, obj = {sorting: '', filtersObj: []}) {
            PortfolioApiService.instance().getPortfolioSummary({
                from: 0,
                to: 500,
                vintageId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.vintageId ? obj.filtersObj.vintageId : null) : null,
                countryId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.countryId ? obj.filtersObj.countryId : null) : null,
                regionId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.regionId ? obj.filtersObj.regionId : null) : null,
                appellationId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.appellationId ? obj.filtersObj.appellationId : null) : null,
                wineId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.ghostWineId ? obj.filtersObj.ghostWineId : null) : null,
                producerId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.ghostProducerId ? obj.filtersObj.ghostProducerId : null) : null,
                colorId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.colorId ? obj.filtersObj.colorId : null) : null,
                isEnPrimeur: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.isEnPrimeur ? obj.filtersObj.isEnPrimeur : null) : null
            }).then(res => {
                let list = res.list

                if (obj?.sorting === 'ascending') {
                    list.sort((a, b) => parseInt(a.marketValue.performanceAmount) - parseInt(b.marketValue.performanceAmount))
                } else if (obj?.sorting === 'descending') {
                    list.sort((a, b) => parseInt(b.marketValue.performanceAmount) - parseInt(a.marketValue.performanceAmount))
                }

                commit(MPortfolio.SET_PORTFOLIO_SUMMARY, list)
            });
        },
        [APortfolio.GET_NEW_PORTFOLIO_SUMMARY]({commit, state}, obj = {filtersObj: []}) {
            PortfolioApiService.instance().getNewPortfolioSummary({
                vintageId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.vintageId ? obj.filtersObj.vintageId : null) : null,
                countryId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.countryId ? obj.filtersObj.countryId : null) : null,
                regionId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.regionId ? obj.filtersObj.regionId : null) : null,
                appellationId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.appellationId ? obj.filtersObj.appellationId : null) : null,
                wineId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.ghostWineId ? obj.filtersObj.ghostWineId : null) : null,
                producerId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.ghostProducerId ? obj.filtersObj.ghostProducerId : null) : null,
                colorId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.colorId ? obj.filtersObj.colorId : null) : null,
            }).then(res => {
                commit(MPortfolio.SET_NEW_PORTFOLIO_SUMMARY, res)
            });
        },
        [APortfolio.GET_SOLD_WINES_SUMMARY]({commit, state}, obj = {filtersObj: []}) {
            PortfolioApiService.instance().getSoldWinesSummary({
                vintageId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.vintageId ? obj.filtersObj.vintageId : null) : null,
                countryId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.countryId ? obj.filtersObj.countryId : null) : null,
                regionId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.regionId ? obj.filtersObj.regionId : null) : null,
                appellationId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.appellationId ? obj.filtersObj.appellationId : null) : null,
                wineId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.ghostWineId ? obj.filtersObj.ghostWineId : null) : null,
                producerId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.ghostProducerId ? obj.filtersObj.ghostProducerId : null) : null,
                colourId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.colorId ? obj.filtersObj.colorId : null) : null,
            }).then(res => {
                commit(MPortfolio.SET_SOLD_WINES_SUMMARY, res)
            });
        },
        [APortfolio.GET_SOLD_WINES_LIST]({commit, state}, obj = {sorting: '', filtersObj: []}) {
            PortfolioApiService.instance().getSoldWinesList({
                vintageId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.vintageId ? obj.filtersObj.vintageId : null) : null,
                countryId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.countryId ? obj.filtersObj.countryId : null) : null,
                regionId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.regionId ? obj.filtersObj.regionId : null) : null,
                appellationId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.appellationId ? obj.filtersObj.appellationId : null) : null,
                wineId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.ghostWineId ? obj.filtersObj.ghostWineId : null) : null,
                producerId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.ghostProducerId ? obj.filtersObj.ghostProducerId : null) : null,
                colourId: obj.hasOwnProperty('filtersObj') ? (obj.filtersObj.colorId ? obj.filtersObj.colorId : null) : null,
            }).then(res => {
                let list = res.wines

                if (obj?.sorting === 'ascending') {
                    list.sort((a, b) => parseInt(a.summaryWines.roiPercentageNet) - parseInt(b.summaryWines.roiPercentageNet))
                } else if (obj?.sorting === 'descending') {
                    list.sort((a, b) => parseInt(b.summaryWines.roiPercentageNet) - parseInt(a.summaryWines.roiPercentageNet))
                }

                commit(MPortfolio.SET_SOLD_WINES_LIST, list)
            });
        },
        [APortfolio.GET_USER_SPECIFIC_TRANSACTIONS]({commit, state}, wineVintageId) {
            return PortfolioApiService.instance().getUserSpecificTransactions({
                wineVintageId: wineVintageId
            }).then(res => {
                commit(MPortfolio.SET_USER_SPECIFIC_TRANSACTIONS, res)
            });
        },
        [APortfolio.GET_CANCELLED_EXPIRED_BIDS_AND_OFFERS]({commit, state}, wineVintageId) {
            return PortfolioApiService.instance().getCancelledExpiredBidsAndOffers({
                wineVintageId: wineVintageId
            }).then(res => {
                commit(MPortfolio.SET_CANCELLED_EXPIRED_BIDS_AND_OFFERS, res)
            });
        },
        [APortfolio.GET_PORTFOLIO_PERFORMANCE_ALL_CASE_SIZES]({commit, state}, wineId) {
            PortfolioApiService.instance().getPortfolioPerformanceAllCaseSizes(wineId).then(res => {
                commit(MPortfolio.SET_PORTFOLIO_PERFORMANCE_ALL_CASE_SIZES, res)
            });
        },
        [ACommon.GET_BANNERS]({commit, state}, languageId) {
            return PortfolioApiService.instance().getBanners(languageId).then(res => {
                commit(MCommon.SET_BANNERS, res.list)
            });
        },
        [ACommon.GET_ALL_COUNTRIES]({commit, state}) {
            if (process.client) {
                let token = localStorage.getItem('access_token');
                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                };
                let url = `${state.apiUrl}Filters/getAllCountries`;

                axios.get(url, {headers: headers})
                    .then(response => commit(MCommon.SET_ALL_COUNTRIES, response.data))
                    .catch(() => {
                        commit(MCommon.SET_ALL_COUNTRIES, []);
                    });
            } else {
                commit(MCommon.SET_ALL_COUNTRIES, []);
            }
        },
        [ACommon.GET_ALL_REGIONS]({commit, state}, countryId) {
            if (process.client) {
                let token = localStorage.getItem('access_token');
                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                };
                let url = `${state.apiUrl}Filters/GetAllRegionsForTradableWines?countryId=${countryId}`;

                axios.get(url, {headers: headers})
                    .then(response => commit(MCommon.SET_ALL_REGIONS, response.data))
                    .catch(() => {
                        commit(MCommon.SET_ALL_REGIONS, []);
                    });
            } else {
                commit(MCommon.SET_ALL_REGIONS, []);
            }
        },
        [ACommon.GET_GIFT_COUNTRIES]({commit, state}) {
            if (process.client) {
                let token = localStorage.getItem('access_token');
                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                };
                let url = `${state.apiUrl}Filters/GetGiftDeliveryCountries`;

                axios.get(url, {headers: headers})
                    .then(response => commit(MCommon.SET_GIFT_COUNTRIES, response.data))
                    .catch(() => {
                        commit(MCommon.SET_GIFT_COUNTRIES, []);
                    });
            }
        },
        [ACommon.GET_ALL_APPELLATIONS]({commit, state}) {
            if (process.client) {
                let token = localStorage.getItem('access_token');
                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                };
                let url = `${state.apiUrl}Filters/GetAllAppellations`;

                axios.get(url, {headers: headers})
                    .then(response => commit(MCommon.SET_ALL_APPELLATIONS, response.data))
                    .catch(() => {
                        commit(MCommon.SET_ALL_APPELLATIONS, []);
                    });
            } else {
                commit(MCommon.SET_ALL_APPELLATIONS, []);
            }
        },
        [ACommon.GET_ALL_APPELLATIONS_REGION]({commit, state}, regionId) {
            if (process.client) {
                let token = localStorage.getItem('access_token');
                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                };
                let url = `${state.apiUrl}Filters/GetAppellationsForRegion?regionId=${regionId}`;

                axios.get(url, {headers: headers})
                    .then(response => commit(MCommon.SET_ALL_APPELLATIONS, response.data))
                    .catch(() => {
                        commit(MCommon.SET_ALL_APPELLATIONS, []);
                    });
            } else {
                commit(MCommon.SET_ALL_APPELLATIONS, []);
            }
        },
        [ACommon.RESET_ALL_REGIONS]({commit}) {
            commit(MCommon.SET_ALL_REGIONS, []);
        },
        [ACommon.GET_MOBILE_VERSION]({commit}, isMobile) {
            commit(MCommon.SET_MOBILE_VERSION, isMobile);
        },
        [AOrders.GET_ORDERS]({commit, state}, filtersObj) {
            return OrderApiService.instance()
                .getOrders(null, filtersObj)
                .then(res => {
                    let _localList = res.list.map(order => {
                        return {
                            key: order.pendingOrderId,
                            id: order.pendingOrderId,
                            name: order.wineName,
                            isEnPrimeur: order.isEnPrimeur,
                            appellation: order.appellation,
                            vintage: order.vintage,
                            region: order.regionName,
                            price: order.pricePerCase + order.markupPerCase,
                            units: order.caseQuantity,
                            orderType: order.isBuy ? 'BID' : 'OFFER',
                            wineId: order.wineId,
                            size: order.caseSize,
                            classification: order.classification,
                            caseSizeId: order.caseSizeId,
                            createdOn: order.createdOn,
                            lastTraded: order.lastTraded,
                            warehouse: order.warehouseName,
                            colorId: order.colorId,
                            countryName: order.countryName,
                            commission: order.commission,
                            commissionVat: order.commissionVat,
                            storage: order.storage,
                            storageVat: order.storageVat,
                            insurance: order.insurance,
                            insuranceVat: order.insuranceVat,
                            totalPrice: order.totalPrice,
                            isSecondLabel: order.isSecondLabel,
                            wineName: order.wineName,
                            producerName: order.producerName,
                            producerTypeName: order.producerTypeName,
                            bottlePicture: order.bottlePicture,
                            reason: order.reason,
                            return: order.return,
                            profit: order.profit,
                            status: order.status,
                            expireAfterDays: order.expireAfterDays,
                        };
                    })
                    _localList.sort((a, b) => (a.createdOn < b.createdOn) ? 1 : -1)
                    commit(MOrders.SET_BIDS_AND_OFFERS, _localList);
                });
        },
        [AOrders.GET_TRADES]({commit, state}, {from, to}) {
            let paging = {};

            paging.from = from;
            paging.to = to;

            return OrderApiService.instance()
                .getTradesHistory(paging)
                .then(res => {
                    commit(MOrders.SET_TRADES_HISTORY, res.list);
                });
        },
        [AOrders.GET_HISTORY]({commit, state}, orderID) {
            return OrderApiService.instance()
                .getOrderHistory(orderID)
                .then(res => {
                    commit(MOrders.SET_ORDER_HISTORY, res.list);
                });
        },
        [AOrders.GET_INITIAL_TRADES]({commit}) {
            commit(MOrders.SET_ORDER_HISTORY_NULL);
        },
        [AOrders.SAVE_ORDER](
            {commit, state},
            {price, units, uniqueCasesIds}
        ) {
            return OrderApiService.instance().saveOrder({
                pricePerCase: price,
                quantity: units,
                uniqueCasesIds: uniqueCasesIds,
                pendingOrderId: state.activeOrder.id
            });
        },
        [AOrders.CANCEL_ORDER]({commit, state}, data) {
            return OrderApiService.instance().cancelOrder(data);
        },
        [AOrders.SET_ACTIVE_ORDER]({commit, state}, order) {
            commit(MOrders.SET_ACTIVE_ORDER, order);
        },
        [AOrders.CREATE_ORDER]({commit, state}, order) {
            return OrderApiService.instance().createOrder(order);
        },
        [AOrders.CREATE_MANGOPAY_CARD]({commit, state}, card) {
            return OrderApiService.instance().createMangopayCard(card);
        },
        [AOrders.REGISTER_MANGOPAY_CARD]({commit, state}, cardType) {
            return OrderApiService.instance().registerMangopayCard(cardType);
        },
        [AOrders.CHECK_REFERRAL_CODE]({commit, state}, refCode) {
            return OrderApiService.instance().checkReferralCode(refCode);
        },
        [AOrders.SET_CURRENT_ORDER]({commit, state}, order) {
            commit(MOrders.SET_CURRENT_ORDER, order);
        },
        [AOrders.CREATE_DELIVERY]({commit, state}, order) {
            return OrderApiService.instance().createDelivery(order);
        },
        [AOrders.GET_COLLECTIONS]({commit, state}, lang) {
            return OrderApiService.instance()
                .getCollections(lang)
                .then(res => {
                    commit(MOrders.SET_COLLECTIONS, res);
                });
        },
        [AOrders.GET_TOP_COLLECTIONS]({commit, state}, lang) {
            return OrderApiService.instance()
                .getTopCollections(lang)
                .then(res => {
                    commit(MOrders.SET_TOP_COLLECTIONS, res);
                });
        },
        [AOrders.GET_COLLECTION_DETAILS]({commit, state}, {id, lang}) {
            return OrderApiService.instance()
                .getCollectionDetails(id, lang)
                .then(res => {
                    commit(MOrders.SET_COLLECTION_DETAILS, res);
                });
        },
        [AOrders.GET_DELIVERIES]({commit}) {
            return OrderApiService.instance()
                .getDeliveries()
                .then(res => {
                    commit(MOrders.SET_DELIVERIES, res);
                });
        },
        [AOrders.GET_DELIVERY_DETAILS]({commit, state}, id) {
            return OrderApiService.instance()
                .getDeliveryDetails(id)
                .then(res => {
                    commit(MOrders.SET_DELIVERY_DETAILS, res);
                });
        },
        [AOrders.GET_SALE_ORDERS]({commit}) {
            return OrderApiService.instance()
                .getSaleOrders()
                .then(res => {
                    commit(MOrders.SET_SALE_ORDERS, res);
                });
        },
        [AOrders.GET_SALE_ORDER_DETAILS]({commit, state}, id) {
            return OrderApiService.instance()
                .getSaleOrderDetails(id)
                .then(res => {
                    commit(MOrders.SET_SALE_ORDER_DETAILS, res);
                });
        },
        [AOrders.GET_SALE_ORDER_PDF]({commit, state}, {invoiceId, userId}) {
            return OrderApiService.instance().getSaleOrderPdf(invoiceId, userId);
        },
        [AOrders.BUY_COLLECTION](
            {commit, state},
            {collections, isDelivery, promoCode, isGift, gift, savedCc, psp, isGiftDelivery, browser, device, cardId, browserInfo}
        ) {
            return OrderApiService.instance().buyCollection(
                collections,
                isDelivery,
                promoCode,
                isGift,
                gift,
                savedCc,
                psp,
                isGiftDelivery,
                browser,
                device,
                cardId,
                browserInfo
            );
        },
        [AOrders.STRIPE_PROCESS_ACCOUNT]() {
            return OrderApiService.instance().stripeProcessAccount();
        },
        [AOrders.CHECK_PAYMENT_STATUS]({commit, state}, dataObj) {
            return OrderApiService.instance().checkPayment(dataObj);
        },
        [AOrders.ACTIVATE_GIFT]({commit, state}, dataObj) {
            return OrderApiService.instance().activateGift(dataObj);
        },
        [AOrders.CANCEL_PAYMENT]() {
            return OrderApiService.instance().cancelPayment();
        },
        [AOrders.GET_SAVED_CC]({commit}) {
            return OrderApiService.instance()
                .getSavedCreditCard()
                .then(res => {
                    commit(MOrders.SET_SAVED_CC, res);
                });
        },
        [AOrders.GET_SAVED_MANGOPAY_CC]({commit}) {
            return OrderApiService.instance()
                .getSavedMangopayCreditCard()
                .then(res => {
                    commit(MOrders.SET_SAVED_MANGOPAY_CC, res);
                });
        },
        [AOrders.GET_GIFT_FEES]({commit, state}, id) {
            return OrderApiService.instance().getGiftFees(id).then(res => {
                commit(MOrders.SET_GIFT_FEES, res);
            });
        },
        [AOrders.SET_PAYPAL_SUCCESS]({commit, state}, {token, payerId}) {
            return OrderApiService.instance().setPayPalSuccess(token, payerId);
        },
        [AOrders.SET_PAYPAL_SUCCESS_BID]({commit, state}, {token, payerId}) {
            return OrderApiService.instance().setPayPalSuccessBid(token, payerId);
        },
        [AOrders.SET_PAYPAL_FAIL]({commit, state}, token) {
            return OrderApiService.instance().setPayPalFail(token);
        },
        async [AMarket.GET_WINES]({state, commit, dispatch}, filtersObj) {
            if (process.client) {
                let payload = {
                    vintage: filtersObj.vintage,
                    countryId: filtersObj.countryId,
                    regionId: filtersObj.regionId,
                    appellationId: filtersObj.appellationId,
                    wineId: filtersObj.ghostWineId,
                    producerId: filtersObj.ghostProducerId,
                    colorId: filtersObj.colorId,
                    priceFrom: filtersObj.priceFrom,
                    priceTo: filtersObj.priceTo,
                    from: 0,
                    to: 1500,
                    isEnPrimeur: filtersObj.isEnPrimeur,
                    isFavorites: filtersObj.isFavorites,
                    hasOffers: filtersObj.hasOffers,
                    hasBids: filtersObj.hasBids
                };

                return MarketApiService.instance().getWines(payload).then((res) => {
                    commit(MMarket.ADD_WINES, res.list);
                    commit(MMarket.SET_TOTAL, res.total);
                    return res;
                });
            }
        },
        async [AMarket.GET_HOME_WINES]({state, commit, dispatch}) {
            if (process.client) {
                let filter = state.activeFilter.filter,
                    sortFilters = state.pickedSortFilters,
                    payload = {
                        wineIds: sortFilters.wineIds,
                        caseSizeIds: sortFilters.caseSizeIds,
                        countryIds: sortFilters.countryIds,
                        regionIds: sortFilters.regionIds,
                        classificationsIds: sortFilters.classificationsIds,
                        producerIds: sortFilters.producerIds,
                        colorIds: sortFilters.colorIds,
                        vintages: sortFilters.vintages,
                        drinkFrom: null,
                        drinkTo: null,
                        showOnlyLastTraded: filter === 'ShowOnlyLastTraded',
                        showLatestPrices: filter === 'ShowLatestPrices',
                        showOnlyTigtestSpread: filter === 'ShowOnlyTigtestSpread',
                        showPopular: filter === 'ShowPopular',
                        from: 0,
                        to: 1000
                    };

                return MarketApiService.instance().getWines(payload).then((res) => {
                    dispatch(AMarket.LOAD_SORT_FILTERS, res.aggs);
                    commit(MMarket.ADD_WINES, res.list);
                    commit(MMarket.SET_TOTAL, res.total);
                    return res
                });
            }
        },
        [AMarket.REMOVE_PRODUCERS]({commit}) {
            commit(MMarket.SET_PRODUCERS, [])
        },
        [AMarket.GET_PRODUCERS]({commit}) {
            return MarketApiService.instance().getProducers().then((res) => {
                res.forEach(element => {
                    element.mapId = element.id + '' + Number(element.isWine);
                })
                commit(MMarket.SET_PRODUCERS, res);
                return res;
            });
        },
        [AMarket.GET_TOP_WINES]({commit}) {
            return MarketApiService.instance().getTopWines().then((res) => {
                commit(MMarket.SET_TOP_WINES, res.wines);
                return res;
            });
        },
        [AMarket.GET_TOP_EN_WINES]({commit}) {
            return MarketApiService.instance().getTopEnWines().then((res) => {
                commit(MMarket.SET_TOP_EN_WINES, res.wines);
                return res;
            });
        },
        [AMarket.SET_ACTIVE_FILTER]({commit}, filter) {
            return commit(MMarket.SET_ACTIVE_FILTER, filter);
        },
        [AMarket.REMOVE_WINES]({commit}) {
            commit(MMarket.SET_WINES, []);
        },
        [AMarket.LOAD_SORT_FILTERS]({commit}, aggs) {
            commit(MMarket.SET_SORT_FILTERS, aggs);
        },
        [AMarket.TOGGLE_SEARCH]({commit, state}, fromMarket) {
            commit(MMarket.SET_FROM_MARKET, fromMarket);
            commit(MMarket.SET_OPEN_SEARCH, !state.openSearch);
        },
        [AMarket.SET_PICKED_SORT_FILTERS]({state, commit, getters}, {filterId, key}) {
            let filter = getters[GMarket.GET_PICKED_SORT_FILTERS][key];

            let isPicked = filter.some(item => item == filterId);

            if (!isPicked) filter.push(+filterId);
            else filter.splice(filter.indexOf(filterId), 1);

            return commit(MMarket.SET_PICKED_SORT_FILTERS, {filters: filter, key})
        },
        [AMarket.REMOVE_PICKED_SORT_FILTERS]({commit, getters}) {
            let filters = getters[GMarket.GET_PICKED_SORT_FILTERS];
            for (let key in filters) {
                filters[key] = [];
            }
            return commit(MMarket.SET_PICKED_SORT_FILTERS, {filters});
        },
        [AWines.INIT_FAVS]({commit}) {
            commit(MWines.SET_FAVOURITE_WINES_NULL)
        },
        [AWines.SWITCH_WINE_FAVOURITE]({state, getters}, {wineId}) {
            let inFav = getters[GWines.GET_IS_WINE_FAVOURITE](wineId);
            if (inFav) {
                return WineApiService.instance().deleteFavouriteWine(wineId).then((res) => {
                    state.favouritesMap.delete(wineId.wineId.toString() + '' + wineId.caseSizeId.toString());
                    return res;
                });
            } else {
                return WineApiService.instance().setFavouriteWine(wineId).then((res) => {
                    state.favouritesMap.add(wineId.wineId.toString() + '' + wineId.caseSizeId.toString());
                    return res;
                });
            }
        },
        async [AWines.GET_WINE_DETAILS]({state, commit}, wineObj) {
            if (typeof wineObj === 'undefined') wineObj = state.pickedWineId;
            let wine = await WineApiService.instance().getWineDetails(wineObj);
            let performanceForPeriod = await WineApiService.instance().getWinePerformanceForPeriod(wine.wine.wineVintageId);
            wine.isLogged = true;
            wine.performanceForPeriod = performanceForPeriod;
            commit(MWines.SET_WINE_INFO, wine);
        },
        async [AWines.GET_BARCODE_INFO]({state, commit}, wineId) {
            let wine = await WineApiService.instance().getBarcodeInfo(wineId);
            wine.isLogged = true;
            commit(MWines.SET_NFC_WINE_INFO, wine);
        },
        async [AWines.GET_WINE_DETAILS_NOT_LOGGED]({state, commit}, wineObj) {
            if (typeof wineObj === 'undefined') wineObj = state.pickedWineId;
            let wine = await WineApiService.instance().getWineDetailsNotLogged(wineObj);
            wine.isLogged = false;
            commit(MWines.SET_WINE_INFO, wine);
        },
        [AWines.GET_FAVOURITE_WINES]({commit}) {
            let payload = {
                vintage: null,
                countryId: null,
                regionId: null,
                appellationId: null,
                producerId: null,
                colorId: null,
                isFavorites: true,
                from: 0,
                to: 100,
                isEnPrimeur: ''
            };

            return MarketApiService.instance().getWines(payload).then((res) => {
                commit(MWines.SET_FAVOURITE_WINES, res.list)
            });
        },
        [AWines.GET_WINE_CHART]({commit}, obj = {wineId: 0, startDate: ''}) {
            return WineApiService.instance().getWineDetailsChart(obj).then((res) => {
                commit(MWines.SET_WINE_CHART, res)
                return res;
            });
        },
        [AWines.SELECT_WINE_ID]({commit}, wineObj) {
            commit(MWines.SET_SELECTED_WINE, wineObj);
        },
        [AWines.SET_WINE_DETAILS_NULL]({commit}) {
            commit(MWines.SET_WINE_LOADING);
        },
        [APerformance.GET_REGION_LIST]({dispatch}) {
            dispatch(APerformance.LOAD_REGION_LIST);
        },
        [APerformance.GET_COLOUR_LIST]({dispatch}) {
            dispatch(APerformance.LOAD_COLOUR_LIST);
        },
        [APerformance.GET_MARKET_VALUES]({dispatch}) {
            dispatch(APerformance.LOAD_MARKET_VALUES);
        },
        [APerformance.GET_MARKET_VALUES_BY_WINE]({dispatch}) {
            dispatch(APerformance.LOAD_MARKET_VALUES_BY_WINE);
        },
        [APerformance.GET_BOTTLES]({dispatch}) {
            dispatch(APerformance.LOAD_BOTTLES);
        },
        [APerformance.GET_INVESTMENTS_FILTERS]({dispatch}) {
            dispatch(APerformance.LOAD_INVESTMENTS_FILTERS);
        },
        [APerformance.GET_BIDSOFFERS_FILTERS]({dispatch}) {
            dispatch(APerformance.LOAD_BIDSOFFERS_FILTERS);
        },
        [APerformance.LOAD_REGION_LIST]({commit, state}, userId) {
            PerformanceApiService.instance().getRegion({
                userId: userId
            }).then(res => {
                let newRegionList = res.map((item, index) => {
                    item.regionName = item.wineRegion
                    item.color = state.reglionListColors[index]
                    return item;
                });

                commit(MPerformance.SET_REGION_LIST, newRegionList)
            });
        },
        [APerformance.LOAD_COLOUR_LIST]({commit, state}, userId) {
            PerformanceApiService.instance().getColour({
                from: 0,
                to: 500,
                userId: userId
            }).then(res => {
                let sumOfAllBottles = 0;

                let getSumOfAllBottles = res.map(item => {
                    sumOfAllBottles += parseInt(item.countOfBottlesOwned.replace(',', ''))
                });

                let newColourList = res.map(item => {
                    item.countOfBottlesOwnedPercentage = parseInt((parseInt(item.countOfBottlesOwned.replace(',', '')) / sumOfAllBottles) * 100);
                    return item;
                })

                commit(MPerformance.SET_COLOUR_LIST, newColourList)
            });
        },
        [APerformance.LOAD_MARKET_VALUES]({commit, state}, {startDate}) {
            return PerformanceApiService.instance().getMarketValue({
                startDate: startDate
            }).then(res => {
                commit(MPerformance.SET_MARKET_VALUES, res)
            });
        },
        [APerformance.LOAD_MARKET_VALUES_BY_WINE]({commit, state}, {wineVintageId, startDate}) {
            return PerformanceApiService.instance().getMarketValueByWine({
                wineVintageId: wineVintageId,
                startDate: startDate
            }).then(res => {
                commit(MPerformance.SET_MARKET_VALUES_BY_WINE, res)
            });
        },
        [APerformance.LOAD_BOTTLES]({commit, state}, {startDate}) {
            return PerformanceApiService.instance().getBottles({
                startDate: startDate,
            }).then(res => {
                commit(MPerformance.SET_BOTTLES, res)
            });
        },
        [APerformance.LOAD_INVESTMENTS_FILTERS]({commit}) {
            PerformanceApiService.instance().getUserInvestmentsWineCountries().then(res => {
                commit(MPerformance.SET_INVESTMENTS_FILTERS, {list: res, propName: 'countries'})

                PerformanceApiService.instance().getUserInvestmentsWineColors().then(res1 => {
                    commit(MPerformance.SET_INVESTMENTS_FILTERS, {list: res1, propName: 'colors'})

                    PerformanceApiService.instance().getUserInvestmentsWineRegions().then(res2 => {
                        commit(MPerformance.SET_INVESTMENTS_FILTERS, {list: res2, propName: 'regions'})

                        PerformanceApiService.instance().getUserInvestmentsWineAppellations().then(res3 => {
                            commit(MPerformance.SET_INVESTMENTS_FILTERS, {list: res3, propName: 'appellations'})

                            PerformanceApiService.instance().getUserInvestmentsWineVintages().then(res4 => {
                                commit(MPerformance.SET_INVESTMENTS_FILTERS, {list: res4, propName: 'vintages'})

                                PerformanceApiService.instance().getUserInvestmentsProducersWineNames().then(res5 => {
                                    commit(MPerformance.SET_INVESTMENTS_FILTERS, {list: res5, propName: 'producers'})
                                });
                            });
                        });
                    });
                });
            });
        },
        [APerformance.LOAD_BIDSOFFERS_FILTERS]({commit}) {
            PerformanceApiService.instance().getUserBidsAndOffersWineCountries().then(res => {
                commit(MPerformance.SET_BIDSOFFERS_FILTERS, {list: res, propName: 'countries'})

                PerformanceApiService.instance().getUserBidsAndOffersWineColors().then(res1 => {
                    commit(MPerformance.SET_BIDSOFFERS_FILTERS, {list: res1, propName: 'colours'})

                    PerformanceApiService.instance().getUserBidsAndOffersWineRegions().then(res2 => {
                        commit(MPerformance.SET_BIDSOFFERS_FILTERS, {list: res2, propName: 'regions'})

                        PerformanceApiService.instance().getUserBidsAndOffersWineAppellations().then(res3 => {
                            commit(MPerformance.SET_BIDSOFFERS_FILTERS, {list: res3, propName: 'appellations'})

                            PerformanceApiService.instance().getUserBidsAndOffersWineVintages().then(res4 => {
                                commit(MPerformance.SET_BIDSOFFERS_FILTERS, {list: res4, propName: 'vintages'})

                                PerformanceApiService.instance().getUserBidsAndOffersProducersWineNames().then(res5 => {
                                    commit(MPerformance.SET_BIDSOFFERS_FILTERS, {list: res5, propName: 'producers'})

                                    PerformanceApiService.instance().getUserBidsAndOffersType().then(res6 => {
                                        commit(MPerformance.SET_BIDSOFFERS_FILTERS, {list: res6, propName: 'types'})

                                        PerformanceApiService.instance().getUserBidsAndOffersTypeStatuses().then(res7 => {
                                            commit(MPerformance.SET_BIDSOFFERS_FILTERS, {
                                                list: res7,
                                                propName: 'statuses'
                                            })

                                            PerformanceApiService.instance().getUserBidsAndOffersCaseSizes().then(res8 => {
                                                commit(MPerformance.SET_BIDSOFFERS_FILTERS, {
                                                    list: res8,
                                                    propName: 'caseSizes'
                                                })
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
            })
        },
        [APerformance.LOAD_SOLDWINES_FILTERS]({commit}) {
            PerformanceApiService.instance().getUserSoldWinesCountries().then(res => {
                commit(MPerformance.SET_SOLDWINES_FILTERS, {list: res, propName: 'countries'})

                PerformanceApiService.instance().getUserSoldWinesColors().then(res1 => {
                    commit(MPerformance.SET_SOLDWINES_FILTERS, {list: res1, propName: 'colours'})

                    PerformanceApiService.instance().getUserSoldWinesRegions().then(res2 => {
                        commit(MPerformance.SET_SOLDWINES_FILTERS, {list: res2, propName: 'regions'})

                        PerformanceApiService.instance().getUserSoldWinesAppellations().then(res3 => {
                            commit(MPerformance.SET_SOLDWINES_FILTERS, {list: res3, propName: 'appellations'})

                            PerformanceApiService.instance().getUserSoldWinesVintages().then(res4 => {
                                commit(MPerformance.SET_SOLDWINES_FILTERS, {list: res4, propName: 'vintages'})

                                PerformanceApiService.instance().getUserSoldWinesProducersWineNames().then(res5 => {
                                    commit(MPerformance.SET_SOLDWINES_FILTERS, {list: res5, propName: 'producers'})
                                })
                            })
                        })
                    })
                })
            })
        },
    }
};
